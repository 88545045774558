import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment';

import { Card, Button, Statistic, Popconfirm, Tooltip, Typography } from 'antd';

const { Paragraph } = Typography;

import {
  UnlockOutlined,
} from '@ant-design/icons';

import { Flex, Discrete } from 'app/components';

import { API } from 'app/services';

import { Functions, Notifications } from 'app/utils';

class CardActivation extends React.Component {

  handleRevoke = async () => {

    const { simulator, onSimulatorUpdate } = this.props;

    try {

      // Revoke simulator
      let response = await API.delete(`/simulators/${simulator.id}/revoke`);

      let code = response.data.code;

      onSimulatorUpdate({
        ...simulator,
        activation_code: code,
        activated: false,
        last_activation: undefined
      });

    } catch (error) {

      // Show error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la révocation du simulateur",
        error.message
      )
    }
  }

  render() {

    const { simulator } = this.props;
    const { activated, activation_code, last_activation } = simulator;

    return (
      <Card title="Activation" hoverable extra={

        <>
          {/* Revoke */}
          {activated &&
            <Tooltip title="Révoquer">
              <Popconfirm
                overlayStyle={{ maxWidth: '250px' }}
                title="Êtes-vous sûr de vouloir révoquer le code d'activation de ce simulateur ?"
                onConfirm={this.handleRevoke}
                okButtonProps={{ type: 'danger' }}
                okText="Oui"
                cancelText="Annuler"
                disabled={!activated}
              >
                <Button type="danger" shape="circle" disabled={!activated} >
                  <UnlockOutlined />
                </Button>
              </Popconfirm>
            </Tooltip>
          }
        </>
      }>

        {/* Activation code */}
        <Statistic title="Code d'activation" value={Functions.breakUp(activation_code, 4, ' – ')} suffix={
          <Paragraph copyable={{ text: activation_code }}></Paragraph>
        } />

        {/* Last activation date */}
        {activated && last_activation &&
          <Discrete>

            <Flex spaceBetweenH>
              <span>Utilisé le</span>
              <span>{moment(last_activation).format('DD/MM/YYYY [à] HH:mm:ss')}</span>
            </Flex>

          </Discrete>
        }

      </Card>
    )
  }
}

export default CardActivation;
