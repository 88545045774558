import { EquipmentKind } from "app/enums";

const MESSAGES = {

  'value:required': 'Valeur requise',

  'value:invalid': 'Valeur invalide',

  'value.format:invalid': 'Format invalide',

  'value:used': 'Valeur déjà utilisée',

  'value:unvailable': 'Valeur indisponible'
}

class Validation {

  static parseValidationsErrors(fields, error) {

    if (error.response && error.response.status === 400) {

      // Parse fields errors
      let validationErrors = JSON.parse(error.response.data.message);

      return fields.map((field) => {

        const name = field.name[0];
        let errors = validationErrors.filter(error => error.field == name).map(value => MESSAGES[value.error] ?? value.error)

        return {
          ...field,
          errors: errors
        }
      })
    }

    throw error;
  }

  static validateEquipment(equipment) {

    if (!equipment) {
      return []
    }

    const kind = EquipmentKind.parse(equipment.kind)

    let errors = []

    if (kind) {

      //Missing board
      if (kind.hasBoard && !equipment.board) {
        errors.push('Aucune carte associée')
      }

      if (kind.parent) {

        //No parent
        if (!equipment.parent) {
          errors.push(`Doit être associé à un(e) ${EquipmentKind.name(kind.parent.kind).toLowerCase()}`)
        }

        // //Parent has error
        // if (equipment.parent && this.validateEquipment(equipment.parent).length > 0) {

        //   errors.push(`Un(e) ou plusieurs erreur(s) dans ${EquipmentKind.name(equipment.parent.kind).toLowerCase()}`)
        // }

      }

      if (kind.children) {

        if (!equipment.children) {
          errors.push(`Nécessite ${kind.children.count} ${EquipmentKind.name(kind.children.kind).toLowerCase()}`)

        } else {

          if (equipment.children.length != kind.children.count) {
            errors.push(`Nécessite ${kind.children.count} ${EquipmentKind.name(kind.children.kind).toLowerCase()}`)
          }

          // Check error of childrens
          const childErrors = equipment.children.map((child) => {

            //FIXME: Forcibly set parent
            child.parent = equipment
            return Validation.validateEquipment(child)
          })

          if (childErrors.some((errors) => errors.length > 0)) {
            errors.push(`Un(e) ou plusieurs ${EquipmentKind.name(kind.children.kind).toLowerCase()} ont des erreurs`)
          }
        }
      }

    } else {

      //Unknown kind
      errors.push('Type inconnu')
    }

    return errors
  }

  static isEquipmentAvailable(equipment, traverse = true) {

    // No equipment
    if (!equipment) {
      return {
        available: true
      }
    }

    // Disabled
    if (!equipment.enabled) {
      return {
        available: false,
        reason: 'disabled'
      }
    }

    // Errors
    const errors = this.validateEquipment(equipment)

    if (errors.length > 0) {
      return {
        available: false,
        reason: 'errors'
      }
    }

    // Parent is disabled
    if (traverse) {

      if (equipment.parent) {

        const { available } = this.isEquipmentAvailable(equipment.parent)

        if (!available) {

          return {
            available: false,
            reason: 'parent-unavailable'
          }
        }
      }

      if (equipment.children) {

        const childUnavailable = equipment.children.map((child) => this.isEquipmentAvailable(child, false)).some((availability) => !availability.available)

        if(childUnavailable) {

          return {
            available: false,
            reason: 'child-unavailable'
          }
        }
      }
    }

    // Available
    return {
      available: true
    }
  }
}

export default Validation;
