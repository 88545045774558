import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom'

import moment from 'moment';

import {
  CheckOutlined,
  CloseOutlined,
  LinkOutlined,
  WarningFilled,
  WarningOutlined
} from '@ant-design/icons';

import { Card, Empty, Statistic, Typography, Popconfirm, Button, Badge, Space, Tooltip, Alert } from 'antd';

const { Paragraph } = Typography;

import { Flex, Pin, Unknown, FirmwareVersion, Availability } from 'app/components';

import { BoardStatus, EquipmentKind, EquipmentStatus } from 'app/enums';

import { Functions, Notifications, Validation } from 'app/utils';
import { API } from 'app/services';

import './style.scoped.css';
import CardBoard from '../CardBoard';
import classnames from 'classnames';

import { Link } from 'react-router-dom';

class CardEquipment extends React.Component {

  handleClick = () => {

    const { equipment } = this.props

    this.props.history.push(`/equipments/${equipment.serial}`);
  }

  render() {

    const { equipment, kind, index } = this.props;

    const status = EquipmentStatus.parse(equipment?.status)

    const errors = equipment ? Validation.validateEquipment(equipment) : [ "Non asoscié" ]
    const { available } = Validation.isEquipmentAvailable(equipment)

    const classNames = classnames('equipment-container', {
      'errors': errors.length > 0,
      'unavailable': !available
    })

    return (
      <Card hoverable className={classNames} onClick={this.handleClick}
        title={
          <Space>
            {EquipmentKind.name(kind)}

            {index &&
              <Badge count={`#${index}`} color='#015375' size='small' />
            }
          </Space>
        } extra={
          <Space>
            {equipment &&
              <Availability equipment={equipment} />
            }

            {/* Errors */}
            {errors.length > 0 &&
              <Tooltip overlayInnerStyle={{ padding: 0 }} title={
                <Alert
                  type={available ? "error" : "warning"}
                  message="Problème(s) non résolu(s)"
                  showIcon
                  description={
                    <ul style={{ padding: 0 }}>
                      {errors.map((error, index) => {
                        return (
                          <li key={index}>
                            {error}
                          </li>
                        )
                      })}
                    </ul>
                  }
                />
              }>
                <Badge count={`${errors.length} erreur(s)`} />
              </Tooltip>
            }
          </Space>
        }
      >

        {equipment ? (

          <Flex vertical>

            <Flex spaceBetweenH>

              {/* Serial */}
              <Statistic title="N° de série" value={equipment.serial} suffix={
                <Paragraph copyable={{ text: equipment.serial }}></Paragraph>
              } />

              {/* Status */}
              <Statistic title="Status" value={status?.name}
                prefix={
                  <>
                    {status ? (
                      <Pin color={status.color} />
                    ) : (
                      <Unknown>
                        {equipment.status}
                      </Unknown>
                    )}
                  </>
                } />

              {/* Enabled */}
              <Statistic title="Activé" value=" "
                prefix={
                  <>
                    {equipment.enabled ? (
                      <>
                        <CheckOutlined style={{ color: "green" }} />
                      </>
                    ) : (
                      <>
                        <CloseOutlined style={{ color: "red" }} />
                      </>
                    )}
                  </>
                }
              />

            </Flex>

            {/* BLE Mac address */}
            {EquipmentKind.parse(kind)?.hasBoard &&
              <CardBoard equipment={equipment} readOnly />
            }

          </Flex>

        ) : (
          <Empty description="Non associé" />
        )}

      </Card>
    );
  }
}

export default withRouter(CardEquipment);
