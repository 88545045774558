import React from 'react'
import PropTypes from 'prop-types'

import { Errors } from 'app/components'
import { Badge, Tooltip } from 'antd'

import './style.scoped.css'

class ErrorsBadge extends React.Component {

  render() {

    const { errors } = this.props

    if(!errors || errors.length == 0) {
      return null
    }

    return (
      <Tooltip overlayInnerStyle={{ padding: 0 }} title={
        <Errors errors={errors} />
      }>
        <Badge count={errors.length} title={''} className='badge' status={'error'} />
      </Tooltip>
    )
  }
}

export default ErrorsBadge;
