import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment';

import { GenericPage } from 'app/pages';

import {
  FieldTimeOutlined,
  EditOutlined
} from '@ant-design/icons';

import { Button, Skeleton, PageHeader, Tabs, Drawer, Tooltip, Space } from 'antd';

import { TimelineTab } from './tabs';

import { Flex, Discrete, Pin, FirmwareVersion } from 'app/components';

import { BoardForm } from 'app/forms';

import { BoardStatus } from 'app/enums';

import { BreadcrumbHelper, Functions, Notifications } from 'app/utils';

import { API } from 'app/services';

class BoardPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      isFormOpen: false
    }
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Cartes", '/boards')
      .flush();

    try {

      const id = this.props.match.params['board'];

      // Fetch board
      let response = await API.get(`/boards/${id}`)

      const board = response.data
      const bleMacAddress = Functions.breakUp(board.ble_mac_address, 2, ':');

      // Update breadcrumb
      BreadcrumbHelper.shared
        .append(bleMacAddress)
        .flush();

      this.setState({
        fetching: false,
        board: board
      });

    } catch(error) {

      // Show error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des informations de la carte",
        error.message
      )

      this.props.history.push('/boards')
    }
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (board) => {

    this.setState({
      board: board
    }, this.toggleForm);
  }

  handleTabChange = (key) => {

    window.location.hash = key;
    this.forceUpdate();
  }

  renderBoard() {

    let currentTab = 'timeline';
    let hash = window.location.hash?.substring(1);

    if (hash) {

      switch (hash) {

        case 'timeline':
          currentTab = hash;

        default:
          window.location.hash = currentTab;
          break
      }
    }

    const { board, isFormOpen } = this.state;

    const bleMacAddress = Functions.breakUp(board.ble_mac_address, 2, ':');
    const status = BoardStatus.parse(board.status);

    const title = (
      <Flex vertical>

        {/* BLE Mac address */}
        {bleMacAddress}

        {/* Meta */}
        <Discrete>
          <Space>

            {/* Batch number */}
            <Tooltip title="N° de lot" placement="bottom">
              {board.batch_number}
            </Tooltip>

            {/* Factory firmware */}
            {board.factory_firmware &&
              <Tooltip title="Microgiciel d'usine" placement='bottom'>
                  {board.factory_firmware}
              </Tooltip>
            }

            {/* Production date */}
            <Tooltip title="Date de production" placement="bottom">
              {moment(board.production_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </Tooltip>

            {/* Status */}
            {status ? (
              <Pin color={status.color}>
                <Tooltip title="Statut" placement="bottom">
                  {status.name}
                </Tooltip>
              </Pin>
            ) : (
              <Unknown>
                {board.status}
              </Unknown>
            )}

            {/* Firmware */}
            {board.firmware &&
              <Tooltip title="Micrologiciel" placement="bottom">
                <FirmwareVersion {...board.firmware} />
              </Tooltip>
            }

          </Space>
        </Discrete>

      </Flex>
    )

    const extra = (
      <>

        {/* Edit */}
        <Button key={0} type="primary" shape="circle" onClick={this.toggleForm}>
          <EditOutlined />
        </Button>
      </>
    )

    return (
      <PageHeader title={title} extra={extra} ghost={false}>

        <Tabs activeKey={currentTab} onChange={this.handleTabChange} destroyInactiveTabPane>

          {/* Timeline */}
          <Tabs.TabPane key="timeline" tab={
            <span>
              Chronologie <FieldTimeOutlined className="inline" />
            </span>
          }>
            <TimelineTab board={board} />
          </Tabs.TabPane>

        </Tabs>

        {/* Edit form */}
        <Drawer title="Modification de la carte" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <BoardForm source={board} onSuccess={this.handleFormSuccess} makeRequest={(board, data) => API.put(`/boards/${board.id}`, data)} />
          }

        </Drawer>

      </PageHeader>
    )
  }

  render() {

    const { fetching } = this.state;

    return (
      <GenericPage>

        {fetching ? (

          <PageHeader title="Chargement...">
            <Skeleton />
          </PageHeader>

        ) : (

          this.renderBoard()
        )}

      </GenericPage>
    );
  }
}

export default BoardPage;
