import React from 'react'

import { Link } from 'react-router-dom';

import { GenericPage } from 'app/pages';

import {
  FieldTimeOutlined,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  TabletFilled
} from '@ant-design/icons';

import { Skeleton, PageHeader, Tabs, Button, Tag, Drawer, Alert, Space, Badge, Tooltip } from 'antd';

import { Flex, Discrete, Pin, Availability, Errors } from 'app/components';
import { EquipmentForm } from 'app/forms';

import {
  InformationsTab,
  TimelineTab
} from './tabs';

import { EquipmentKind, EquipmentStatus } from 'app/enums';

import { API } from 'app/services';
import { BreadcrumbHelper, Functions, Notifications, Validation } from 'app/utils';

import './style.scoped.css';

class EquipmentPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      isFormOpen: false
    }
  }

  async componentDidMount() {

    BreadcrumbHelper.shared
      .append("Équipements", '/equipments')
      .flush();

    try {

      const id = this.props.match.params['equipment'];

      // Fetch equipment
      let equipment = (await API.get(`/equipments/${id}`)).data

      BreadcrumbHelper.shared
        .append(equipment.serial)
        .flush();

      this.setState({
        fetching: false,
        equipment: equipment
      });

    } catch(error) {

      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la récupération des informations de l'équipement",
        error.message
      )

      this.props.history.push('/equipments')
    }
  }

  handleTabChange = (key) => {

    window.location.hash = key;
    this.forceUpdate();
  }

  toggleForm = () => {

    this.setState({
      isFormOpen: !this.state.isFormOpen
    });
  }

  handleFormSuccess = (equipment) => {

    this.setState({
      equipment: equipment
    }, this.toggleForm);
  }

  onEquipmentUpdate = (equipment, onComplete) => {

    this.setState({
      equipment: equipment
    }, onComplete);
  }

  renderEquipment() {

    const { equipment, isFormOpen } = this.state;

    const kind = EquipmentKind.parse(equipment.kind);
    const status = EquipmentStatus.parse(equipment.status);

    const hasInformations = kind?.hasBoard || kind?.children || kind?.parent

    let currentTab = hasInformations ? 'informations' : 'timeline';
    let hash = window.location.hash?.substring(1);

    if (hash) {

      switch (hash) {

        case 'informations':
        case 'timeline':
          currentTab = hash;

        default:
          window.location.hash = currentTab;
          break
      }
    }

    let serial = equipment.serial.split('-');

    const errors = Validation.validateEquipment(equipment)

    const title = (
      <Flex vertical>

        <Space>

          {/* Serial */}
          <span>
            <Link to={`/simulators/${equipment.simulator}`}>{serial[0]}</Link>
            -
            {serial[1]}

          </span>

          {/* Availability */}
          <Availability equipment={equipment} />

        </Space>

        {/* Meta */}
        <Discrete>
          <Space>

            <Tag>
              {kind?.name ?? equipment.kind}
            </Tag>

            {/* Status */}
            {status ? (
              <Pin color={status.color}>
                {status.name}
              </Pin>
            ) : (
              <Unknown>
                {equipment.status}
              </Unknown>
            )}

            {/* Enabled */}
            <Flex space={4} centerV>
              {equipment.enabled ? (
                <>
                  <CheckOutlined style={{ color: "green" }} />
                  Activé
                </>
              ) : (
                <>
                  <CloseOutlined style={{ color: "red" }} />
                  Désactivé
                </>
              )}
            </Flex>

          </Space>
        </Discrete>

      </Flex>
    )

    const extra = (
      <>
        {/* Edit */}
        <Button key={0} type="primary" shape="circle" onClick={this.toggleForm}>
          <EditOutlined />
        </Button>
      </>
    )

    return (
      <PageHeader title={title} extra={extra} ghost={false}>

        <Flex vertical space={8}>

          {/* Errors */}
          <Errors errors={errors} />

        </Flex>

        <Tabs activeKey={currentTab} onChange={this.handleTabChange} destroyInactiveTabPane>

          {/* Board */}
          {hasInformations &&
            <Tabs.TabPane tab="Informations" key="informations" >
              <InformationsTab equipment={equipment} onEquipmentUpdate={this.onEquipmentUpdate} />
            </Tabs.TabPane>
          }

          {/* Timeline */}
          <Tabs.TabPane tab={
            <span>
              Chronologie <FieldTimeOutlined className="inline" />
            </span>
          } key="timeline" >
            <TimelineTab equipment={equipment} />
          </Tabs.TabPane>

        </Tabs>

        {/* Edit form */}
        <Drawer title="Modification de l'équipement" placement="right" visible={isFormOpen} onClose={this.toggleForm} maskClosable={false} >

          {isFormOpen &&
            <EquipmentForm source={equipment} simulator={equipment.simulator} onSuccess={this.handleFormSuccess} makeRequest={(equipment, data) => API.put(`/equipments/${equipment.serial}`, data)} />
          }

        </Drawer>

      </PageHeader>
    )
  }

  render() {

    const { fetching, equipment } = this.state;

    return (
      <GenericPage>

        {fetching ? (

          <PageHeader title="Chargement...">
            <Skeleton />
          </PageHeader>

        ) : (

          this.renderEquipment()
        )}

      </GenericPage>
    );
  }
}

export default EquipmentPage;
