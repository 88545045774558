import React from 'react';

import fr_FR from 'antd/es/locale/fr_FR';
import { ConfigProvider } from 'antd';

import { createBrowserHistory } from 'history';

import { Router, Switch, Redirect } from "react-router-dom";
import { DummyRoute, ProtectedRoute } from 'app/routes';

import { NotFoundPage } from 'app/pages';
import { LoginPage, LogoutPage } from 'app/pages';
import { UserActivatePage } from 'app/pages';
import { PasswordRecoveryPage, PasswordRecoveryConfirmPage, PasswordRecoveryCancelPage } from 'app/pages';

import { CustomersPage, CustomerPage } from 'app/pages';
import { SimulatorsPage, SimulatorPage } from 'app/pages';

import { FirmwaresPage } from 'app/pages';

import { EquipmentsPage } from 'app/pages';
import { EquipmentPage } from 'app/pages';

import { BoardsPage, BoardPage } from 'app/pages';

import { Splash } from 'app/components';

import { Env } from 'app/env'

import { Auth } from 'app/services';

import 'app/styles/main.css';
import 'antd/dist/antd.min.css';

import moment from 'moment';
import 'moment/locale/fr';
moment.locale('fr');

const history = createBrowserHistory();

class App extends React.Component {

  constructor() {
    super();

    //Load last token
    let shouldCheckAuth = Auth.loadToken();

    this.state = {
      authChecked: !shouldCheckAuth
    };

    if(shouldCheckAuth) {

      //Check auth token
      new Promise(async () => {

        await Auth.checkToken();

        this.setState({ authChecked: true });
      });
    }

    if(Env.DEPLOY_TARGET == "INTEGRATION") {
      document.title = `<INT> ${document.title}`;
    }
  }

  render() {

    let { authChecked } = this.state;

    // User token not checked
    if (!authChecked) {

      return (
        <Splash message="Vérification du jeton de connexion" />
      )
    }

    return (
      <ConfigProvider locale={fr_FR}>
        <Router history={history}>

          <Switch>

            {/* Login route */}
            <DummyRoute path="/login" exact page={LoginPage} />

            {/* Login route */}
            <DummyRoute path="/users/activate" exact page={UserActivatePage} />

            {/* Recovery route */}
            <DummyRoute path="/password/recovery" exact page={PasswordRecoveryPage} />

            {/* Reset route */}
            <DummyRoute path="/password/recovery/confirm" exact page={PasswordRecoveryConfirmPage} />

            {/* Recovery route */}
            <DummyRoute path="/password/recovery/cancel" exact page={PasswordRecoveryCancelPage} />

            {/* Logout route */}
            <ProtectedRoute path="/logout" exact page={LogoutPage} />

            {/* Root URL */}
            <Redirect from='/' to='/simulators' exact />

            {/* Customers list */}
            <ProtectedRoute path="/customers" exact page={CustomersPage} roles={Auth.ROLE_API} />

            {/* Customer details */}
            <ProtectedRoute path="/customers/:customer([0-9]+)" exact page={CustomerPage} roles={Auth.ROLE_API} />

            {/* Simulators list */}
            <ProtectedRoute path="/simulators" exact page={SimulatorsPage} roles={Auth.ROLE_API} />

            {/*Boards list*/ }
            <ProtectedRoute path="/boards" exact page={BoardsPage} roles={Auth.ROLE_API} />

            {/*Board details*/ }
            <ProtectedRoute path="/boards/:board([0-9]+)" exact page={BoardPage} roles={Auth.ROLE_API} />

            {/* Simulator details */}
            <ProtectedRoute path="/simulators/:simulator([0-9]+)" exact page={SimulatorPage} roles={Auth.ROLE_API} />

            {/* Firmwares list */}
            <ProtectedRoute path="/firmwares" exact page={FirmwaresPage} roles={Auth.ROLE_API} />

            {/* Equipments list */}
            <ProtectedRoute path="/equipments" exact page={EquipmentsPage} roles={Auth.ROLE_API} />

            {/* Equipment details */}
            <ProtectedRoute path="/equipments/:equipment" exact page={EquipmentPage} roles={Auth.ROLE_API} />

            {/* NotFound route */}
            <DummyRoute page={NotFoundPage} />

          </Switch>

        </Router>

        {/* Watermark */}
        {Env.DEPLOY_TARGET == 'INTEGRATION' &&

          <div id="watermark">
            INTEGRATION BUILD
            <span>for internal purpose only</span>
          </div>
        }

      </ConfigProvider>
    );
  }
}

export default App;
