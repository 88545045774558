import React from 'react'
import PropTypes from 'prop-types'

import { Alert, Badge, Tag, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Flex } from 'app/components';
import { Validation } from 'app/utils';
import { EquipmentKind } from 'app/enums';

class Availability extends React.Component {

  reason(equipment, reason) {

    const kind = EquipmentKind.parse(equipment.kind)

    switch(reason)
    {
      // Disabled
      case 'disabled':

        return (
          <>
            Équipement <Tag><CloseOutlined style={{ color: "red" }} /> Désactivé</Tag>
          </>
        )

      case 'errors':

        return (
          <>
            Un ou plusieurs problèmes ne sont pas résolus
          </>
        )

      case 'parent-unavailable':

        return (
          <>
            {EquipmentKind.name(kind.parent.kind)} <Tag>{equipment.parent.serial}</Tag> indisponible
          </>
        )

      case 'child-unavailable':

        return (
          <>
            Un(e) ou plusieurs {EquipmentKind.name(kind.children.kind).toLowerCase()} sont indisponible
          </>
        )

      default:
        return `Raison inconnue (${reason})`
    }
  }

  render() {

    const { equipment } = this.props;

    const { available, reason } = Validation.isEquipmentAvailable(equipment)

    if(available) {

      // return (
      //   <Badge count={'Disponible'} status={'success'} />
      // )

      return null;
    }

    return (
      <Tooltip overlayInnerStyle={{ padding: 0, minWidth: '25em' }} title={
        <Alert
          type="warning"
          message="Équipement indisponible"
          description={
            <Flex vertical space={4}>

              <span>
                {this.reason(equipment, reason)}
              </span>

            </Flex>
          }
          showIcon
        />
      }>
        <Badge count={'Indisponible'} status={'warning'} />
      </Tooltip>
    )
  }
}

export default Availability;
