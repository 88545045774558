import React from 'react'
import PropTypes from 'prop-types'

import { Col, Row } from 'antd';

import { Flex } from 'app/components';

import {
  CardBoard,
  CardEquipment
} from './cards';

import { EquipmentKind } from 'app/enums';

class InformationsTab extends React.Component {

  render() {

    const { equipment, onEquipmentUpdate } = this.props;

    const kind = EquipmentKind.parse(equipment.kind)

    return (
      <Flex vertical space={16}>

        <Row gutter={[16, 16]}>

          {/* Board */}
          {kind?.hasBoard &&
            <Col span={10}>
              <CardBoard equipment={equipment} onEquipmentUpdate={onEquipmentUpdate} />
            </Col>
          }

          {/* Parent */}
          {kind?.parent &&
            <Col span={10}>
              <CardEquipment kind={kind.parent.kind} equipment={equipment.parent} onEquipmentUpdate={onEquipmentUpdate} />
            </Col>
          }

          {/* Children */}
          {kind?.children && [...Array(kind.children.count)].map((_, index) => {

            let child = equipment.children ? equipment.children[index] : undefined

            if (child) {
              child.parent = equipment
            }

            return (
              <Col span={10} key={index}>
                <CardEquipment kind={kind.children.kind} equipment={child} index={index+1} onEquipmentUpdate={onEquipmentUpdate} />
              </Col>
            )
          })}

        </Row>

      </Flex>
    );
  }
}

export default InformationsTab;
