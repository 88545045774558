/**
 * @param {any[]} universe
 * @param {any?} defaultValue
 * @param {callback<id, values>} parse
 */
const seal = (universe, defaultValue, parse, name) => {

  const allValues = Object.values(universe);

  return Object.seal({

    ...universe,

    /**
     * Gets all value.
     * @returns all value
     */
    values: () => allValues,

    /**
     * Gets the default value.
     */
    default: defaultValue,

    /**
     * Returns a value from its ID.
     * @param {any} id
     * @returns a value otherwise undefined
     */
    parse: (id) => {
      return parse(id, allValues);
    },

    /**
     * Returns the name of an enum from its ID.
     * @param {any} id
     * @returs the name otherwise the ID
     */
    name: (id) => {

      const kind = parse(id, allValues);

      if(!kind) {
        return kind
      }

      if(!name) {
        return kind.name
      }

      return name(kind)
    },

    index: (id, fallback = -1) => {

      let index = allValues.findIndex((kind) => kind.id == id)
      return index >= 0 ? index : fallback
    },
  })
};

export default seal;
