import React from 'react'
import PropTypes from 'prop-types'

import { withRouter } from 'react-router-dom';

import moment from 'moment';

import {
  LinkOutlined
} from '@ant-design/icons';

import { Card, Empty, Statistic, Typography, Popconfirm, Button, Tooltip } from 'antd';

const { Paragraph } = Typography;

import { Flex, Pin, Unknown, FirmwareVersion } from 'app/components';

import { BoardStatus } from 'app/enums';

import { Functions, Notifications } from 'app/utils';
import { API } from 'app/services';

import './style.scoped.css';

class CardBoard extends React.Component {

  handleClick = () => {

    const { equipment } = this.props
    const { board } = equipment;

    //this.props.history.push(`/boards/${board.id}`);
  }

  handleDissociate = async () => {

    const { equipment, onEquipmentUpdate } = this.props;

    try {

      await API.put(`/equipments/${equipment.serial}`, {
        ...equipment,
        board_ref: null
      });

      onEquipmentUpdate({
        ...equipment,
        board: undefined
      });

    } catch(error) {

      // Unhandled error
      Notifications.error(
        "Oops, quelque chose s'est mal passé...",
        "Une erreur est survenue lors de la dissociation de la carte",
        error.message
      )
    }
  }

  render() {

    const { equipment, readOnly } = this.props;
    const { board } = equipment;

    const status = BoardStatus.parse(board?.status);

    return (
      <Card size={readOnly ? "small" : "default"} title="Carte éléctronique" hoverable={!readOnly} className="board-container" onClick={this.handleClick}
        extra={
          <>
            {board && !readOnly &&
              <Popconfirm
                overlayStyle={{ maxWidth: '250px' }}
                title={`Êtes-vous sûr de vouloir dissocier la carte de l'équipement ?`}
                onConfirm={this.handleDissociate}
                okButtonProps={{ type: 'danger' }}
                okText="Oui"
                cancelText="Annuler"
              >
                <Tooltip title="Dissocier">
                  <Button type="danger" shape="circle">
                    <LinkOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            }
          </>
        }
      >

        {board ? (

          <Flex vertical>

            <Flex spaceBetweenH>

              {/* BLE Mac address */}
              <Statistic title="Adresse MAC BLE" value={Functions.breakUp(board.ble_mac_address, 2, ':')} suffix={
                <Paragraph copyable={{ text: Functions.breakUp(board.ble_mac_address, 2, ':') }}></Paragraph>
              }/>

              {/* Firmware */}
              {board.firmware &&
                <Statistic title="Micrologiciel" value={'‎'} suffix={
                  <FirmwareVersion {...board.firmware} />
                }/>
              }

            </Flex>

            <Flex spaceBetweenH>

              {/* Batch number */}
              <Statistic title="N° de lot" value={board.batch_number} />

              {/* Production dater */}
              <Statistic title="Date de production" value={moment(board.production_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} />

              {/* Status */}
              <Statistic title="Statut" value={status?.name ?? false} prefix={
                <>
                  {status ? (
                    <Pin color={status.color} />
                  ) : (
                    <Unknown color="red" help="Statut inconnu">
                      {board.status}
                    </Unknown>
                  )}
                </>
              } />

            </Flex>

          </Flex>

        ) : (
          <Empty description="Aucune carte associée" />
        )}

      </Card>
    );
  }
}

export default withRouter(CardBoard);
