import React from 'react'
import PropTypes from 'prop-types'

import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

import { Form, Select, Tooltip, Space, Button } from 'antd';

import { Notifications, Validation } from 'app/utils';

class ChoiceForm extends React.Component {

  constructor(props) {
    super(props);

    const { choice } = this.props;

    this.state = {
      fetching: true,
      pending: false,
      choices: [],
      fields: [
        { label: [ 'choice' ], value: choice }
      ]
    }
  }

  componentDidMount = async () => {

    const { choices } = this.props;

    try {

      this.setState({
        fetching: false,
        choices: await Promise.resolve(choices)
      })

    } catch(error) {

      console.log(error);
    }
  }

  handleChange = (_, fields) => {

    this.setState({
      fields: fields
    });
  }

  handleSubmit = async (data) => {

    const { makeRequest } = this.props;
    const { fields, choices } = this.state;

    let choice = fields[0].value;

    this.setState({
      pending: true
    });

    let newState = {
      pending: false
    };

    var onComplete;

    try {

      let response = await makeRequest(choice);

      //Notify success
      onComplete = () => {
        this.props.onSuccess(choices, choice)
      }

    } catch (error) {

      try {

        // Validation
        newState.fields = Validation.parseValidationsErrors(this.state.fields, error);

      } catch (error) {

        // Unhandled error
        Notifications.error(
          "Oops, quelque chose s'est mal passé...",
          "Une erreur est survenue lors de la mise à jour des informations",
          error.message
        )
      }

    } finally {
      this.setState(newState, onComplete);
    }
  }

  render() {

    const { placeholder, renderOption, onCancel, filterOption } = this.props;
    const { fields, choices, fetching, pending } = this.state;

    let choice = fields[0].value;

    return (
      <Form
        name="choice-form"
        fields={fields}
        layout="inline"
        onFieldsChange={this.handleChange}
        disabled={pending}
        onFinish={this.handleSubmit}
      >
        {/* Choice */}
        <Form.Item name="choice" style={{ marginRight: '8px' }}>
          <Select
            style={{ width: '250px' }}
            loading={fetching}
            placeholder={placeholder}
            showSearch={filterOption ? true : false}
            allowClear
            filterOption={(input, choice) => {

              return filterOption(input, choice.value, choices);
            }}
          >
            {choices.map((choice, i) => renderOption(choice, i))}
          </Select>
        </Form.Item>

        <Space>

          {/* Validate */}
          <Tooltip title="Valider">
            <Button form="choice-form" htmlType="submit" type="link" disabled={!choice} style={{ padding: 0 }}>
              <CheckOutlined style={{ color: "green" }} />
            </Button>
          </Tooltip>

          {/* Cancel */}
          <Tooltip title="Annuler">
            <Button onClick={onCancel} type="link" style={{ padding: 0 }}>
              <CloseOutlined style={{ color: "red" }} />
            </Button>
          </Tooltip>

        </Space>
      </Form>
    );
  }
}

export default ChoiceForm;
