import { Typeof } from 'app/utils';
import { EquipmentKind } from '../enums';

class Compare {

  //-- Boolean

  static boolean(a, b) {
    return Compare.number(Number(a), Number(b));
  }

  static booleanInverse(a, b) {
    return Compare.boolean(b, a)
  }

  //-- Number

  static number(a, b) {

    let aIsValid = Typeof.number(a)
    let bIsValid = Typeof.number(b)

    if(aIsValid && bIsValid) {
      return a - b
    } else {

      if (aIsValid) {
        return -1
      } else {
        return 1
      }
    }
  }

  static numberInverse(a, b) {
    return Compare.number(b, a)
  }

  //-- String

  static string(a, b) {

    let aIsValid = Typeof.string(a)
    let bIsValid = Typeof.string(b)

    if(aIsValid && bIsValid) {
      return a.localeCompare(b)
    } else {

      if(aIsValid) {
        return -1
      } else {
        return 1
      }
    }
  }

  static stringInverse(a, b) {
    return Compare.string(b, a)
  }

  //-- Version

  static version(a, b) {

    let aIsValid = Typeof.string(a)
    let bIsValid = Typeof.string(b)

    if (aIsValid && bIsValid) {
      return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    } else {

      if (aIsValid) {
        return -1
      } else {
        return 1
      }
    }
  }

  static versionInverse(a, b) {
    return Compare.version(b, a)
  }

  //-- Custom

  static equipment(a, b) {

    let fallback = 999

    if (a.simulator > b.simulator) return  1
    if (a.simulator < b.simulator) return -1

    if (status = this.kind(a.kind, b.kind, fallback)) {
      return status
    }

    if (EquipmentKind.index(a.kind, fallback) > EquipmentKind.index(b.kind, fallback)) return  1
    if (EquipmentKind.index(a.kind, fallback) < EquipmentKind.index(b.kind, fallback)) return -1

    if (a.identifier > b.identifier) return  1
    if (a.identifier < b.identifier) return -1

    return 0
  }

  static kind(a, b) {

    let fallback = 999

    if (EquipmentKind.index(a, fallback) > EquipmentKind.index(b, fallback)) return  1
    if (EquipmentKind.index(a, fallback) < EquipmentKind.index(b, fallback)) return -1

    return 0
  }
}

export default Compare;
