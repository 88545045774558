import React from 'react'
import PropTypes from 'prop-types'

import { Alert } from 'antd';

class Errors extends React.Component {

  render() {

    const { errors } = this.props;

    // No errors
    if(!errors || errors.length == 0) {
      return null;
    }

    return (
      <Alert
        type="error"
        message="Problème(s) non résolu(s)"
        showIcon
        description={
          <ul style={{ padding: 0 }}>
            {errors.map((error, index) => {
              return (
                <li key={index}>
                  {error}
                </li>
              )
            })}
          </ul>
        }
      />
    );
  }
}

export default Errors;
